<template>
  <l-default>
    <div :is="component" />
  </l-default>
</template>

<script>
import { mapGetters } from 'vuex'
import LDefault from '../layouts/LDefault'
import PageDirectCall from '@/pages/PageDirectCall.vue'
import PageFreeDirectCall from '@/pages/PageFreeDirectCall.vue'

export default {
  name: 'PageHome',
  components: { LDefault },
  computed: {
    ...mapGetters(['isLoggedIn']),
    component () {
      return this.isLoggedIn ? PageDirectCall : PageFreeDirectCall
    }
  }
}
</script>
